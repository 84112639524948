/* eslint-disable @typescript-eslint/no-explicit-any */
import JsonView from "react18-json-view";
import { Collapsed, DisplaySize } from "react18-json-view/dist/types";
import "react18-json-view/src/dark.css";
import "react18-json-view/src/style.css";

export interface InspectProps {
  src: any;
  collapsed?: Collapsed;
  dark?: boolean;
  displaySize?: DisplaySize;
}

const Inspect = (props: InspectProps) => <JsonView displaySize {...props} />;

export default Inspect;
