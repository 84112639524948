import { Stack } from "react-bootstrap";
import { useUser } from "./App";
import Inspect from "./components/Inspect";

const Profile = () => {
  const { user } = useUser();

  return (
    <Stack>
      Profile
      <Inspect src={user} />
    </Stack>
  );
};

export default Profile;
