import axios from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import { emojify } from "node-emoji";
import { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Container,
  Form,
  InputGroup,
  Stack,
} from "react-bootstrap";
import { Emojione } from "react-emoji-render";
import Note from "../model/Note";
import { useUser } from "./App";

const Notes = () => {
  const [text, setText] = useState("");
  const [notes, setNotes] = useState<Note[]>([]);
  const [error, setError] = useState();

  const { user } = useUser();

  useEffect(() => {
    axios
      .get("/api/note")
      .then(({ data }) => {
        setNotes(data);
        setError(undefined);
      })
      .catch((ex) => {
        setNotes([]);
        setError(ex.message);
      });
  }, []);

  const onNoteSave = () => {
    if (!isEmpty(text)) {
      axios
        .post("/api/note", { text, author: user?.given_name })
        .then(({ data }) => {
          setNotes([data, ...notes]);
          setText("");
          setError(undefined);
        })
        .catch((ex) => {
          setError(ex.message);
        });
    }
  };

  return (
    <Container fluid className="m-0 p-0">
      <Stack gap={2}>
        {error && <Alert variant="danger">{JSON.stringify(error)}</Alert>}

        {user && (
          <InputGroup id="note">
            <Form.Control
              placeholder="Add a Note"
              aria-label="Note"
              value={text}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={(e) => e.code === "Enter" && onNoteSave()}
            />
            <Button onClick={onNoteSave}>Save</Button>
          </InputGroup>
        )}
        {notes &&
          notes.map((note) => (
            <Stack key={note.id}>
              <Card>
                <Card.Header>
                  <Stack direction="horizontal">
                    {note.author}
                    {moment().diff(moment(note.time), "day") > 0 ? (
                      <Badge bg="secondary" className="ms-auto">
                        {moment(note.time).calendar()}
                      </Badge>
                    ) : (
                      <Badge bg="secondary" className="ms-auto">
                        {moment(note.time).fromNow()}
                      </Badge>
                    )}
                  </Stack>
                </Card.Header>
                <Card.Body>
                  <Emojione text={emojify(note.text)} />
                </Card.Body>
              </Card>
            </Stack>
          ))}
        {isEmpty(notes) && <>No Notes</>}
      </Stack>
    </Container>
  );
};

export default Notes;
