import axios from "axios";
// import moment from "moment";
import { useEffect, useState } from "react";
import { Alert, Stack } from "react-bootstrap";
import DataGrid, { Column } from "react-data-grid";
import { Game, Player } from "../model/Cribbage";
import User from "../model/User";

import "react-data-grid/lib/styles.css";
import Inspect from "./components/Inspect";

type CribbageProps = {
  user?: User;
};

type Row = {
  date: Date;
  one: number;
  two: number;
  game: Game;
};

const Cribbage = (props: CribbageProps) => {
  const { user } = props;

  const [player, setPlayer] = useState<Player>();
  const [games, setGames] = useState<Game[]>();

  const [columns] = useState<Column<Row, string>[]>([
    {
      key: "date",
      name: "Date",
      // formatter: ({ row }) => moment(row.game.date).calendar(),
    },
    {
      key: "one",
      name: "Player One",
    },
    {
      key: "two",
      name: "Player Two",
    },
  ]);

  const [rows, setRows] = useState<Row[]>([]);

  useEffect(() => {
    let ignore = false;

    if (user && !player) {
      axios
        .get(`/api/cribbage/player`, {
          params: { userId: `${user.provider}-${user.id}` },
        })
        .then(({ data }) => {
          !ignore && setPlayer(data[0]);
        })
        .catch((ex) => {
          console.error(ex);
          !ignore && setPlayer(undefined);
        });
    }

    return () => {
      ignore = true;
    };
  }, [user, player]);

  useEffect(() => {
    let ignore = false;

    if (user && player && !games) {
      axios
        .get<Game[]>(`/api/cribbage/player/${player._id}/games`)
        .then(({ data }) => {
          if (!ignore) {
            setGames(data);
            setRows(
              data.map((game) => ({
                date: game.date,
                one: game.playerOne.score,
                two: game.playerTwo.score,
                game,
              }))
            );
          }
        })
        .catch((ex) => {
          console.error(ex);
          !ignore && setGames(undefined);
        });
    }

    return () => {
      ignore = true;
    };
  }, [user, player, games]);

  return (
    <Stack gap={2}>
      <h1>Cribbage</h1>
      <DataGrid columns={columns} rows={rows} />
      <Alert>
        <Inspect src={{ user, player, games, columns, rows }} collapsed={1} />
      </Alert>
    </Stack>
  );
};

export default Cribbage;
