import PropTypes from "prop-types";
import { HTMLAttributes } from "react";
import { Button, Image, Stack } from "react-bootstrap";
import User from "../../model/User";

type UserBadgeProps = {
  user?: User;
} & HTMLAttributes<HTMLDivElement>;

const UserBadge = ({ user, ...props }: UserBadgeProps) => {
  return (
    <div {...props}>
      <Stack direction="horizontal" gap={2}>
        {user && (
          <>
            <Image
              src={user.picture}
              alt="-"
              roundedCircle
              width={32}
              title={`${user.displayName} (${user.email})`}
            />
            <Button size="sm" href="/auth/logout">
              Logout
            </Button>
          </>
        )}
        {!user && (
          <Button size="sm" href="/auth/login">
            Login with Google
          </Button>
        )}
      </Stack>
    </div>
  );
};

UserBadge.defaultProps = {
  user: undefined,
};

UserBadge.propTypes = {
  user: PropTypes.object,
};

export default UserBadge;
