import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Cribbage from "./Cribbage";
import Home from "./Home";
import Notes from "./Notes";
import Profile from "./Profile";

const router = createBrowserRouter([
  { index: true, element: <Home/>},
  {    
    element: <App />,
    children: [
      { path: "/cribbage", element: <Cribbage /> },
      { path: "/notes", element: <Notes /> },
      { path: "/profile", element: <Profile /> },
      { path: "*", element: <div>404</div> },
    ],
  },
]);

export default router;
