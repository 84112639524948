import axios from "axios";
import { useEffect, useState } from "react";
import { Alert, Card, Container, Stack } from "react-bootstrap";
import { Outlet, useOutletContext } from "react-router-dom";
import "./App.css";
import User from "../model/User";
import UserBadge from "./components/UserBadge";

export type ContextType = {
  user?: User;
};

export const useUser = () => useOutletContext<ContextType>();

const App = () => {
  const [user, setUser] = useState<User>();
  const [error, setError] = useState();

  useEffect(() => {
    axios
      .get("/auth/user")
      .then(({ data }) => {
        setUser(data ? data : undefined);
        setError(undefined);
      })
      .catch((ex) => {
        setUser(undefined);
        setError(ex.message);
      });
  }, []);

  return (
    <Container>
      <Stack gap={2} className="mt-2">
        {error && (
          <Alert variant="danger">
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </Alert>
        )}
        <Card>
          <Card.Header>
            <Stack direction="horizontal" gap={2}>
              stej.com
              <UserBadge className="ms-auto" user={user} />
            </Stack>
          </Card.Header>
          <Card.Body>
            <Outlet context={{ user }} />
          </Card.Body>
        </Card>
      </Stack>
    </Container>
  );
};

export default App;
